.m-login__container {
    border: 1px solid var(--secondaryColor);
    border-radius: 10px;
    padding: 0 0 0 0;
}

.m-login.m-login--2.m-login-2--skin-3 .m-login__container .m-login__head .m-login__title {
    color: white !important;
    font-family: AP-Text-Regular, Arial, Helvetica, sans-serif;
    text-align: center;
}

.m-login.m-login--2 .m-login__wrapper {
    padding: 0 !important;
}

.m-login.m-login--2 .m-login__wrapper .m-login__container {
    width: 340px !important;
    margin: auto !important;
}

.m-login__head {
    background-color: var(--secondaryColor);
    padding: 8px;
    margin-top: 0;
    text-transform: uppercase;
}

.m-login__title {
    font-size: 14px !important;
    margin-bottom: 0;
    font-weight: bold;
}

.m-login__form.m-form {
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 15px !important;
}

.login-page-container {
    background-color: white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.m-login.m-login--2 .m-login__wrapper .m-login__container .m-login__logo {
    margin: 0 !important;
    text-align: center !important;
}

.m-login__logo {
    text-align: center !important;
}

.m-login__signin {
    position: relative;
    top: -13px;
}

.m-login.m-login--2.m-login-2--skin-3 .m-login__container .m-login__form .form-control {
    color: var(--secondaryColor) !important;
    background: white !important;
    border: 1px solid var(--mainColor) !important;
    border-radius: 0 !important;
}

.m-login__form ::placeholder {
    color: transparent !important;
}

.m-login__form :-ms-input-placeholder {
    color: transparent !important;
}

.m-login__form ::-ms-input-placeholder {
    color: transparent !important;
}

.m-login__btn {
    text-transform: uppercase;
    font-family: AP-Text-Regular, Arial, Helvetica, sans-serif !important;
    font-size: 12px;
    width: 100%;
    padding: 12px 25px 12px 25px;
    color: #fff !important;
    border-color: #fff !important;
    background-color: var(--mainColor);
}

.btn.btn-focus.active, .btn.btn-focus.focus, .btn.btn-focus:focus {
    color: #fff !important;
}

.btn-outline-focus.m-btn--air, .btn-focus.m-btn--air, .m-btn--gradient-from-focus.m-btn--air {
    box-shadow: none !important;
}

.m-login.m-login--2 .m-login__wrapper .m-login__container .m-login__form .m-login__form-action {
    text-align: right;
    margin-top: 20px;
}

.m-login.m-login--2 .m-login__wrapper .m-login__container .m-login__form .m-login__form-action .m-login__btn {
    padding: 7px !important;
    width: 100px !important;
    border-radius: 0 !important;
    height: 28px !important;
    border: 1px solid var(--mainColor);
    font-size: 12px;
    margin-top: 0;
    line-height: 10px;
}

.btn-outline-focus.m-btn--air.focus, .btn-outline-focus.m-btn--air:focus,
.btn-outline-focus.m-btn--air:hover,
.btn-focus.m-btn--air.focus,
.btn-focus.m-btn--air:focus,
.btn-focus.m-btn--air:hover,
.m-btn--gradient-from-focus.m-btn--air.focus,
.m-btn--gradient-from-focus.m-btn--air:focus,
.m-btn--gradient-from-focus.m-btn--air:hover {
    box-shadow: none !important;
    border-color: white !important;
}

.m-login.m-login--2 .m-login__wrapper .m-login__container .m-login__form {
    margin-top: 30px !important;
}

.login-failed-text {
    color: #d62050;
    text-align: center;
    font-family: AP-Text-Regular, Arial, Helvetica, sans-serif;
    margin-top: 10px;
    font-size: 12px;
}

.loginIconCircle {
    color: #ffc98c;
    font-size: 120px;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.m-grid__item.m-grid__item--fluid.m-login__wrapper {
    background-color: white !important;
    margin-top: 150px !important;
}

.leopard-loading-icon {
    text-align: center;
    margin-left: 125px;
    float: left;
}

.dx-texteditor.dx-editor-outlined {
    border-radius: 0;
}

.dx-texteditor-input {
    border-radius: 0;
}

.leopard-version-text {
    font-family: AP-Text-Regular, Arial;
    font-size: 9px;
    text-align: center;
    position: relative;
    margin-top: 15px;
    top: 15px;
}

.m-login__btn:hover {
    background-color: var(--mainColor) !important;
    border-color: var(--mainColor) !important;
}

.login-cover-progress-icon {
    display: none;
    z-index: 100;
    position: absolute;
    margin-left: calc(50% - 14px);
    margin-top: 250px;
}

.login-cover-progress-icon .fa-spinner {
    color: var(--mainColor);
    font-size: 35px;
}

.login-cover-progress-icon .logging-out-text {
    font-family: Arial;
    font-size: 14px;
    margin-top: 3px;
    color: var(--mainColor);
}

.fas.fa-spinner.fa-pulse {
    color: var(--mainColor) !important;
}
