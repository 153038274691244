@font-face {
    font-family: 'AP-Display-Bold';
    font-style: normal;
    font-weight: normal;
    src: url('./fonts/ap_type_display-bold.woff') format('woff');
}

@font-face {
    font-family: 'AP-Text-Regular';
    font-style: normal;
    font-weight: normal;
    src: url('./fonts/ap_type_text-regular.woff') format('woff');
}

@font-face {
    font-family: 'AP-Text-Bold';
    font-style: normal;
    font-weight: normal;
    src: url('./fonts/ap_type_text-bold.woff') format('woff');
}

@font-face {
    font-family: 'AP-Display-Regular';
    font-style: normal;
    font-weight: normal;
    src: url('./fonts/ap_type_display-regular.woff') format('woff');
}

:root {
    --mainColor: #FF8100;
    --shadeColor: #FFF3E7;
    --hoverColor: #ffd8b5;
    --secondaryColor: #212529;
}