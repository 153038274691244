.leopard-table-header {
    color: var(--mainColor);
}

.dx-datagrid-table .dx-row.dx-column-lines.dx-header-row {
    background-color: var(--shadeColor);
}

.dx-datagrid .dx-column-lines > td {
    border-bottom: 1px solid #dee1e3;
}

.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > tr > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > tr > td {
    background-color: var(--mainColor);
    color: white !important;
}

.dx-datagrid-table .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row):not(.dx-row-focused) > td:not(.dx-focused) {
    color: black;
    background-color: var(--hoverColor);
}

.dx-datagrid-table .dx-data-row.dx-state-hover .dx-command-edit {
    color: var(--mainColor);
}

.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-lines) > td,
.dx-datagrid-rowsview .dx-selection.dx-row:hover:not(.dx-row-lines) > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > td,
.dx-datagrid-rowsview .dx-selection.dx-row:hover:not(.dx-row-focused) > td {
    color: #333;
}

.dx-scrollable-scroll-content {
    background-color: var(--mainColor);
}

.dx-datagrid .dx-sort-down:before,
.dx-datagrid .dx-sort-up:before {
    color: var(--mainColor);
}

.dx-toolbar {
    background-color: transparent;
}

.dx-datagrid-header-panel {
    background-color: var(--shadeColor);
}

.dx-toolbar .dx-button .dx-icon {
    color: #8899a8;
}

.leopard-config-table th {
    color: var(--mainColor);
}

.leopard-color-orange {
    color: var(--mainColor) !important;
}

.leopard-gridview-toolbar {
    background-color: var(--shadeColor);
    border-bottom: 1px solid #dee1e3;
}

.leopard-gridview-advancedFilter {
    background-color: var(--shadeColor);
    border-bottom: 1px solid #dee1e3;
}

.ace_editor.ace-tomorrow {
    border-left: 5px solid green;
}

.leopard-hr-splitter {
    border-bottom: 2px solid var(--mainColor);
}

.dx-master-detail-cell {
    background-color: #eaeaea !important;
}

.dx-field-item-label-text {
    color: black;
    font-weight: bold;
}

.dx-multiview-item-content {
    background-color: white;
}

.leopard-accordion-title {
    color: var(--mainColor);
}

.dx-field-label {
    color: #485159;
}

.leopard-color-lightorange {
    background-color: #FFE0C0;
}

.leopard-bgcolor-orange {
    background-color: var(--mainColor);
}

.leopard-ordercolor-orange {
    border-color: var(--mainColor);
}

.leopard-font-bold {
    font-weight: bold;
}

.drag-and-drop-selection-count {
    background-color: #EA4335;
    color: white;
}

.dx-datagrid-headers {
    color: black;
}

.dx-calendar-caption-button.dx-state-hover .dx-button-content {
    color: white !important;
}

.dx-button-mode-contained.dx-state-hover .dx-icon {
    color: white !important;
}

.dx-popup-bottom .dx-toolbar-item-content .dx-button {
    background-color: var(--mainColor);
    color: white;
    border-radius: 0;
}

.dx-popup-bottom .dx-toolbar-item-content .dx-button.dx-state-hover {
    background-color: var(--mainColor);
}

.leopard-top-menu-bar {
    background-color: var(--secondaryColor);
}

.leopard-userinfo-panel {
    background-color: white;
    border: 2px solid var(--mainColor);
}

.leopard-loggedin-user-tag {
    color: var(--mainColor);
}

.leopard-loggedin-user-tag:hover {
    color: var(--mainColor);
}

.m-nav .m-nav__separator {
    border-bottom: 1px solid #d1d1d1 !important;
}

.m-nav .m-nav__item:hover:not(.m-nav__item--disabled) > .m-nav__link .m-nav__link-icon,
.m-nav .m-nav__item:hover:not(.m-nav__item--disabled) > .m-nav__link .m-nav__link-text,
.m-nav .m-nav__item:hover:not(.m-nav__item--disabled) > .m-nav__link .m-nav__link-arrow,
.m-nav .m-nav__item.m-nav__item--active > .m-nav__link .m-nav__link-icon,
.m-nav .m-nav__item.m-nav__item--active > .m-nav__link .m-nav__link-text,
.m-nav .m-nav__item.m-nav__item--active > .m-nav__link .m-nav__link-arrow {
    color: #6f727d !important;
}

.m-nav .m-nav__separator {
    border-top: 1px dashed white !important;
    color: white;
}

.leopard-logout-button .dx-button-content {
    color: white;
    background-color: var(--mainColor);
}

.leopard-logout-button:hover .dx-button-content {
    background-color: var(--mainColor);
}

.leopard-button .dx-button-content {
    color: white;
    background-color: var(--mainColor);
}

.leopard-button:hover .dx-button-content {
    color: white;
    background-color: var(--mainColor);
}

.dx-button-mode-contained.dx-state-hover {
    background-color: var(--mainColor);
}

.leopard-userinfo-category {
    color: black;
}

.m-nav .m-nav__item > .m-nav__link .m-nav__link-text {
    color: var(--mainColor) !important;
}

.leopard-topmenu-bar-hidebutton {
    background-color: var(--mainColor);
    color: white;
}

.leopard-topmenu-bar-hidebutton:hover {
    background-color: var(--mainColor);
}

.leopard-left-panel-container {
    border-right: 5px solid var(--mainColor);
}

.leopard-leftmenu-item {
    color: var(--mainColor) !important;
}

.leopard-leftmenu-item.selected {
    color: white !important;
    background-color: var(--mainColor) !important;
}

.leopard-buttonlink .dx-button-content {
    color: var(--mainColor);
}

.leopard-buttonlink:hover .dx-button-content {
    color: var(--mainColor) !important;
}

.dx-toolbar .dx-button .dx-icon {
    color: #8899a8 !important;
}

.leopard-standard-button {
    color: white;
    background-color: var(--mainColor);
}

.leopard-standard-button:hover {
    background-color: var(--mainColor) !important;
}

.leopard-navbar-title-text {
    color: white;
}

.ace_editor.ace-tomorrow {
    border: 1px solid #DDDDDD !important;
}

hr {
    border-top: 1px solid #DDDDDD;
}

.dx-button-mode-contained.dx-state-active {
    color: white;
    background-color: var(--mainColor);
}

.dx-button-mode-contained.dx-state-focused {
    color: white;
    background-color: var(--mainColor);
}

.orange-arrow-top {
    border-bottom: 10px solid var(--mainColor);
}

.dx-datagrid-group-panel .dx-group-panel-item {
    color: black;
    border: 1px solid var(--mainColor);
}

.dx-datagrid-rowsview .dx-row.dx-group-row td {
    border-top-color: #dee1e3 !important;
    border-bottom-color: #dee1e3 !important;
}

.dx-datagrid-pager {
    border-top: 1px double #dee1e3;
}

.dx-fileuploader-input-wrapper .dx-fileuploader-button.dx-button.dx-button-normal .dx-button-content {
    color: var(--mainColor) !important;
    border: 1px solid var(--mainColor) !important;
    background-color: #fff !important;
}

.dx-fileuploader-input-wrapper .dx-fileuploader-button.dx-button.dx-button-normal {
    background-color: #fff !important;
}

.dx-fileuploader-file-container {
    border-bottom: 1px dotted #969696;
}

.dx-list-group-header {
    border-top: 0px solid var(--mainColor);
    background: var(--shadeColor);
    color: var(--mainColor);
    border-bottom: 1px solid #dee1e3;
}

.dx-popup-normal .dx-scrollable-content .dx-empty-message {
    color: #dc3545;
}

.leopard-configure-grid-tablerow:hover {
    background-color: var(--mainColor);
}

.dx-layout-manager .dx-field-item:not(.dx-first-row) {
    padding-top: 0px !important;
}

.leopard-page-number.selected {
    background-color: var(--mainColor);
    border: 1px solid var(--mainColor);
    color: white;
}

.leopard-page-number:hover {
    background-color: var(--mainColor);
    border: 1px solid var(--mainColor);
    color: white;
}

.leopard-page-button {
    color: var(--mainColor) !important;
}

.leopard-page-button:hover {
    color: var(--mainColor) !important;
}

.leopard-page-button-container {
    color: var(--mainColor);
}

.leopard-page-button-container:hover {
    color: var(--mainColor);
}

.leopard-page-infotext {
    color: #6c757d;
}

.leopard-field-attention-mark {
    color: var(--mainColor);
}

.dx-datagrid-pager.dx-pager .dx-page-sizes .dx-page-size {
    border: 1px solid var(--mainColor);
    color: var(--mainColor);
}

.dx-datagrid-pager.dx-pager .dx-page-sizes .dx-page-size.dx-selection {
    color: white;
}

.leopard-buttonlink {
    background-color: transparent !important;
}

.leopard-buttonlink .dx-button-content {
    background-color: transparent !important;
}

.leopard-buttonlink:hover {
    background-color: transparent !important;
}

.leopard-leftmenu-category-addbutton {
    color: var(--mainColor);
}

.leopard-leftmenu-category-addbutton:hover {
    color: var(--mainColor) !important;
}

.leopard-leftmenu-option-panel {
    border: 2px solid var(--mainColor);
    border-top-color: var(--mainColor);
    border-bottom-color: var(--mainColor);
    background-color: white;
}

.leopard-option-panel-stepindex {
    color: var(--mainColor);
}

.leopard-screen-cover {
    background-color: white;
}

.leopard-screen-menucover {
    background-color: white;
}

.react-grid-item {
    border: 3px solid #808080;
}

.leopard-templateview-cell-layout {
    background-color: white;
}

.leopard-templateview-cell-topbar {
    background-color: gray;
}

.leopard-moving-handler {
    color: white;
}

.leopard-moving-handler-text {
    color: white;
}

.leopard-bgcolor-pink {
    background-color: #da4088;
}

.leopard-bgcolor-green {
    background-color: #01BF2A;
}

.leopard-bgcolor-orange {
    background-color: #E76962;
}

.leopard-largeloading-progress-text {
    color: var(--mainColor);
}

.leopard-largeloading-progress-icon {
    color: var(--mainColor);
}

.leopard-preview-text {
    color: gray;
}

.leopard-settings-warning-text {
    color: #ef3038;
    background-color: #efe597;
}

.leopard-pagination-blocker {
    background-color: white;
}

.leopard-link-for-menuoption {
    color: var(--mainColor);
}

.leopard-link-for-menuoption-parent.selected,
.leopard-link-for-menuoption-parent:hover {
    background-color: var(--shadeColor);
}

.leopard-page-number {
    color: var(--mainColor);
    border: 1px solid var(--mainColor);
}

.leopard-topmenu-bar-hidebutton {
    color: white;
}

.react-grid-item.react-grid-placeholder {
    background: var(--mainColor);
}

.orange-arrow-left {
    border-right: 10px solid var(--mainColor);
}

.leopard-chart-designer .dx-item-content.dx-box-item-content {
    border-right: 1px solid #dee1e3;
}

.leopard-column-designer .dx-item-content.dx-box-item-content {
    border-right: 1px solid #dee1e3;
}

.leopard-formeditor-designer .dx-item-content.dx-box-item-content {
    border-right: 1px solid #dee1e3;
}

.leopard-document-editor-designer .dx-item-content.dx-box-item-content {
    border-right: 1px solid #dee1e3;
}

.leopard-page-container {
    border-top: 1px solid #dee1e3;
}

.leopard-field-attention-info {
    color: #3399F0;
}

.leopard-link-with-tooltip {
    border-bottom: 1px dashed var(--mainColor);
    cursor: default;
}

.stiDesignerToolBar {
    background-color: var(--mainColor);
}

.stiDesignerStatusPanel {
    background-color: var(--mainColor);
}

.stiDesignerToolButton_Mouse {
    background-color: var(--mainColor);
}

.stiJsViewerNavigatePanel {
    background-color: var(--mainColor);
}

.stiDesignerFileMenu {
    background-color: var(--mainColor);
}

.stiDesignerFileMenuItemSelected {
    background-color: var(--mainColor);
}

.stiDesignerFileMenuItemOver {
    background-color: var(--mainColor);
}

.leopard-autorefresh-button_wrapper.disabled .leopard-autorefresh-button {
    background-color: #b5b5b5 !important;
    color: white !important;
}

.leopard-autorefresh-button {
    background-color: var(--mainColor);
    color: white;
}

.stiJsViewerFormButtonDefault {
    background-color: var(--mainColor);
    border: 1px solid var(--mainColor);
    color: white;
}

.stiJsViewerFormButtonOver {
    background-color: var(--mainColor);
    border: 1px solid var(--mainColor);
    color: white;
}

.stiJsViewerTextBoxDefault {
    border: 1px solid #dee1e3;
}

.stiJsViewerCheckBoxImageBlock {
    border: 1px solid #dee1e3;
    background: #fff;
}

.stiJsViewerCheckBoxImageBlockOver {
    border: 1px solid var(--mainColor);
    background: none;
}

.stiJsViewerRadioButtonOutCircle {
    border: 1px solid #dee1e3;
    background-color: #fff;
}

.stiJsViewerRadioButtonOutCircleOver {
    border: 1px solid var(--mainColor);
    background: none;
}

.stiJsViewerFormButtonSelected {
    background-color: var(--mainColor);
    border: 1px solid var(--mainColor);
    color: white;
}

.stiJsViewerParametersMenuItemOver .stiJsViewerParametersMenuItemInnerTable {
    background: var(--mainColor);
    border: 1px solid var(--mainColor);
    color: white;
}

.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-hover {
    background-color: var(--mainColor) !important;
    color: white !important;
}

.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-list-item-selected {
    background-color: var(--mainColor) !important;
    color: white !important;
}

.stiJsViewerStandartSmallButtonSelected {
    background: var(--mainColor);
    color: white;
}

.stiJsViewerStandartSmallButtonOver {
    background: #FEF4E8;
}

.stiJsViewerMenuStandartItemOver {
    background: #FEF4E8;
}

.stiJsViewerMenuStandartItemSelected {
    background: var(--mainColor);
    color: white;
}

.stiJsViewerFormHeader {
    color: black;
}

.stiJsViewerDropDownList {
    border: 1px solid #dee1e3;
}

.stiJsViewerTextBoxDisabled {
    background: #fff;
    border: 1px solid #dee1e3;
}

.stiJsViewerToolBarTable {
    border: none;
}

.stiDesignerFileMenuItemDisabled {
    color: #ffaf60;
}

.wizardFormStepItemSelected {
    background: var(--mainColor);
}

.stiDesignerFormHeader {
    color: var(--mainColor);
}

.stiDesignerForm {
    border: 1px solid var(--mainColor);
}

.stiDesignerFormButton_Mouse {
    border: 1px solid #dee1e3;
}

.stiDesignerTextBox_Mouse {
    border: 1px solid #dee1e3;
}

.stiDesignerClearAllStyles .stiDesignerFormButton_Mouse {
    background-color: var(--mainColor);
    text-transform: uppercase;
    color: white;
    border: 1px solid var(--mainColor);
}

.stiDesignerFormButtonOver_Mouse {
    background-color: var(--mainColor);
    border: 1px solid var(--mainColor);
    color: white;
    text-transform: uppercase;
}

.stiDesignerPropertiesGroupHeaderButtonSelected_Mouse {
    background: var(--mainColor);
    color: white;
}

.stiDesignerPropertiesGroupHeaderButtonOver_Mouse {
    background: var(--mainColor);
    color: white;
}

#Textbox_customQueryAttributes .dx-button.dx-button-has-icon {
    background-color: transparent;
}

#Textbox_customQueryAttributes .dx-button.dx-button-has-icon i {
    color: var(--mainColor) !important;
}

#Textbox_clientSideQueryEditor .dx-button.dx-button-has-icon {
    background-color: transparent;
}

#Textbox_clientSideQueryEditor .dx-button.dx-button-has-icon i {
    color: var(--mainColor) !important;
}

#customQueryAttributesInput .dx-button.dx-button-has-icon {
    background-color: transparent;
}

#customQueryAttributesInput .dx-button.dx-button-has-icon i {
    color: var(--mainColor) !important;
}

#numberOfSeriesForChartInput .dx-button.dx-button-has-icon {
    background-color: transparent;
}

#numberOfSeriesForChartInput .dx-button.dx-button-has-icon i {
    color: var(--mainColor) !important;
}

.numberOfSeriesOnChart .dx-button.dx-button-has-icon {
    background-color: transparent;
}

.numberOfSeriesOnChart .dx-button.dx-button-has-icon i {
    color: var(--mainColor) !important;
}

.stiThemesMenuHeader {
    background: var(--mainColor);
}

.stiDesignerPropertiesPanelFooterTabButtonSelected_Mouse {
    border-bottom: 2px solid var(--mainColor);
    color: var(--mainColor);
}

.stiDesignerPropertiesGroupContainer {
    background-color: white;
}

.stiDesignerStatusPanelButtonSelected_Mouse {
    background-color: var(--mainColor);
}

.stiDesignerStatusPanelButtonOver_Mouse {
    background-color: var(--mainColor);
}

.stiDesignerToolButtonOver_Mouse {
    background-color: var(--mainColor);
}


.leopard-table-with-fixedcolumn table {
    background-color: #aaa;
}

.leopard-table-with-fixedcolumn thead th {
    color: var(--mainColor);
}

.leopard-table-with-fixedcolumn thead th:nth-child(1) {
    background-color: white;
}

.leopard-table-with-fixedcolumn tbody tr td:nth-child(1) {
    background-color: white;
}

.leopard-configure-grid-tablerow:hover .leopard-table-fixed-column {
    background-color: var(--mainColor);
    color: white;
}

.leopard-tileviewitem-image.selected {
    border: 4px solid var(--mainColor);
}

.leopard-popupwindow-loading-progress {
    background-color: white;
}

.leopard-configurepanel-title-button {
    color: var(--mainColor);
}

.leopard-configurepanel-title-button:hover {
    color: var(--mainColor);
}

.leopard-photogallery-enlarged-image {
    background-color: white;
}

.leopard-settings-panel-contentgroup.numberOfSeriesOnChart .dx-scrollview-content {
    border: 1px solid #dee1e3;
}

.toolbar-warming-up-text {
    color: var(--mainColor);
}

.leopard-error-message-override {
    background-color: var(--shadeColor) !important;
}

.leopard-menuoption-add-button {
    color: var(--mainColor);
}

.leopard-menuoption-configbox-container {
    border: 1px solid #d3d3d3;
}

.leopard-option-panel-block.error {
    background-color: #F74D61;
    color: white;
    padding: 5px;
    text-align: center;
}

.leopard-level-bar {
    color: white;
}

.leopard-dashboard-level-text {
    color: white;
}

.leopard-empty-dataview-warning {
    color: black;
}

#cronExpressionForReportPart6Input .dx-button.dx-button-has-icon {
    background-color: transparent;
}

#cronExpressionForReportPart6Input .dx-button.dx-button-has-icon i {
    color: var(--mainColor) !important;
}

.textbox_MiniButton .dx-button.dx-button-has-icon {
    background-color: transparent;
}

.textbox_MiniButton .dx-button.dx-button-has-icon i {
    color: var(--mainColor) !important;
}

.dx-texteditor-buttons-container .dx-button.dx-button-has-icon {
    background-color: transparent;
}

.dx-texteditor-buttons-container .dx-button.dx-button-has-icon i.fas {
    color: var(--mainColor) !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.dx-placeholder {
    color: #afbdc9;
}

.leopard-textchanged-indicator {
    color: green;
}

.topMenuItemSearchInput_MiniButton {
    color: lightgray !important;
}

.dx-field-item-required-mark {
    color: red !important;
}

.leopard-application-loading-cover {
    background-color: white;
}

.leopard-text-color.green {
    color: green;
}

.leopard-text-color.orange {
    color: var(--mainColor);
}

.leopard-text-color.red {
    color: red;
}

.leopard-text-center {
    text-align: center;
}

.dx-header-row .leopard_gridview_column_template.allow-sorting {
    color: var(--mainColor);
}

.dx-column-indicators .dx-sort-index-icon {
    color: var(--mainColor);
}

.dx-list .dx-empty-message, .dx-list-item {
    color: #afbdc9;
}

.dx-list-item-content {
    color: black;
}

.leopard-maximum-data-reached-warning {
    color: #F74D61;
}

.mobile_designer_loader {
    border-top: 6px solid rgba(255, 290, 0, .2);
    border-right: 6px solid rgba(255, 290, 0, .2);
    border-bottom: 6px solid rgba(255, 290, 0, .2);
    border-left: 6px solid var(--mainColor);
}

.js_viewer_loader {
    border-top: 6px solid rgba(255, 290, 0, .2);
    border-right: 6px solid rgba(255, 290, 0, .2);
    border-bottom: 6px solid rgba(255, 290, 0, .2);
    border-left: 6px solid var(--mainColor);
}

.leopard-photocallery-container-largephoto-text {
    background-color: rgba(0, 0, 0, 0.4);
}

tr.dx-row.dx-column-lines.dx-header-row td.dx-command-edit.dx-col-fixed.dx-cell-focus-disabled.dx-datagrid-drag-action {
    background-color: var(--shadeColor);
}

.dx-button-mode-contained.dx-button-default .dx-icon {
    color: var(--mainColor);
}

.dx-button-mode-contained.dx-state-hover .dx-icon {
    color: var(--mainColor) !important;
}

.leopard-workspace-dataview-removebutton,
.leopard-workspace-dataview-addbutton {
    color: var(--mainColor) !important;
}

.leopard-dropdown-item-doublelines .desc {
    color: #9b9b9b;
}

.dx-list-item.dx-state-hover .leopard-dropdown-item-doublelines .desc {
    color: #ededed;
}

.dx-list-item-selected .leopard-dropdown-item-doublelines .desc {
    color: #ededed;
}

.export-option-popup-link {
    color: var(--mainColor);
}

.export-option-popup-link:hover {
    background-color: var(--shadeColor);
}

.leopard-photocallery-container-largephoto-text {
    color: white;
}

.leopard-templateview-cell-topbar {
    color: white;
}

.dx-scrollbar-horizontal .dx-scrollable-scroll {
    background-color: transparent;
}

.dx-scrollbar-vertical .dx-scrollable-scroll {
    background-color: transparent;
}

.leopard-photocallery-container-nophoto {
    background-color: white;
}

.leopard-report-loading-spinner {
    background-color: rgba(255, 255, 255, 0.8);
}

.leopard-report-loading-spinner-built-in {
    background-color: rgba(255, 255, 255, 0.8);
}

#reportDesignerContent .leopard-report-loading-spinner {
    background-color: rgba(255, 255, 255, 0.8);
}

.leopard-editpanel-protective-cover {
    background-color: white;
}

.leopard-static-toast .toast-header {
    color: white;
}

.leopard-static-toast.error .toast-header {
    background-color: #EA4335;
}

.leopard-static-toast .toast-description {
    color: black;
    background-color: white;
}

.leopard-static-toast.error .toast-description {
    color: #EA4335;
}

.leopard-action-group-popup {
    background-color: white;
    border: 2px solid var(--mainColor);
}

.dx-popup-wrapper:not(.dx-overlay-modal) > .dx-overlay-content {
    border: 1px solid var(--mainColor);
}

.dx-popup-wrapper:not(.dx-overlay-modal) .dx-popover-arrow:after {
    border: 1px solid var(--mainColor);
}

.dx-overlay-wrapper:not(.dx-overlay-modal) .dx-context-menu .dx-submenu {
    border: 1px solid var(--mainColor);
}

.dx-overlay-wrapper:not(.dx-overlay-modal) .dx-context-menu .dx-menu-separator {
    background-color: var(--mainColor);
}

.dx-overlay-wrapper:not(.dx-overlay-modal) .dx-overlay-content.dx-menu-base .dx-submenu .dx-menu-item-wrapper .dx-item-content.dx-menu-item-content .dx-menu-item-text {
    color: var(--mainColor);
}

.dx-overlay-wrapper:not(.dx-overlay-modal) .dx-context-menu.dx-overlay-content {
    background-color: transparent;
}

.data-previewer-container {
    border: 1px solid #d8d8d8;
}

.leopard-splitter-control {
    border-top: 1px solid #d8d8d8;
}

.leopard-documenteditor-left-hand-side .MuiTableRow-root.MuiTableRow-hover:hover {
    background-color: transparent;
}

.leopard-documenteditor-left-hand-side .MuiSvgIcon-root {
    color: var(--mainColor);
}

.MuiButton-textPrimary {
    color: white !important;
    background-color: var(--mainColor) !important;
}

.leopard-documenteditor-left-hand-side .ValidationIcon-badge-2 {
    color: var(--mainColor);
}

.MuiDialog-paper {
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175) !important;
    border: 1px solid #dee1e3 !important;
}

.MuiBackdrop-root {
    background-color: transparent !important;
}

.MuiDialog-container.MuiDialog-scrollPaper {
    background-color: rgba(255, 255, 255, .8);
}

#alert-dialog-confirmdelete-title {
    border-bottom: 1px solid #dee1e3;
}

#alert-dialog-confirmdelete-description {
    color: #333;
}

.leopard-right-panel-container .leopard-splitter-control {
    background-color: white;
}

.react-grid-layout {
    background-color: white;
}

.dx-header-row .leopard_gridview_column_template.disallow-sorting {
    color: gray;
    cursor: default;
}

.dx-header-row .dx-cell-focus-disabled.dx-datagrid-drag-action .dx-datagrid-text-content {
    color: gray;
    cursor: default;
}

.dx-header-row .leopard_gridview_column_template.allow-sorting .dx-datagrid-text-content {
    color: var(--mainColor) !important;
}

.leopard-map-toolbar {
    background-color: var(--shadeColor);
    border-bottom: 1px solid #dee1e3;
}

.leopard-formeditor-toolbar {
    background-color: var(--shadeColor);
    border-bottom: 1px solid #dee1e3;
}

.leopard-htmlformbuilder-toolbar {
    background-color: var(--shadeColor);
    border-bottom: 1px solid #dee1e3;
}

.leopard-chart-toolbar {
    background-color: var(--shadeColor);
    border-bottom: 1px solid #dee1e3;
}

.leopard-timeline-toolbar {
    background-color: var(--shadeColor);
    border-bottom: 1px solid #dee1e3;
}

.leopard-map-marker-text {
    color: white;
}

.layout-popup-link {
    color: var(--mainColor);
    border-left: 6px solid transparent;
}

.layout-popup-link:hover {
    background-color: var(--shadeColor);
}

.layout-popup-link.selected {
    background-color: var(--shadeColor);
    border-left: 6px solid var(--mainColor);
}

.leopard-button-dynamic-size .dx-button-content {
    color: white;
    background-color: var(--mainColor);
}

.leopard-button-dynamic-size:hover .dx-button-content {
    color: white;
    background-color: var(--mainColor);
}

.leopard-regex-tester-result-icon .indicator.error {
    color: #EA4335;
}

.leopard-regex-tester-result-icon .indicator.valid {
    color: #34A853;
}

.leopard-leftmenu-settingspanel-addbutton {
    color: var(--mainColor);
}

.leopard-dataview-report-addsource {
    color: var(--mainColor) !important;
}


.leopard-menupanel-splitter-container {
    background-color: var(--mainColor);
}

.leopard-menupanel-splitter-counter {
    background-color: var(--mainColor);
    color: white;
}

.dx-scheduler-all-day-table-cell.dx-scheduler-focused-cell, .dx-scheduler-date-table-cell.dx-scheduler-focused-cell {
    -webkit-box-shadow: inset 0 0 0 1px transparent;
    box-shadow: inset 0 0 0 1px transparent;
}

.dx-scheduler-all-day-table-cell.dx-state-focused, .dx-scheduler-date-table-cell.dx-state-focused {
    background-color: transparent;
    opacity: 1;
}

.dx-scheduler-all-day-table-cell.dx-state-hover.dx-state-focused, .dx-scheduler-date-table-cell.dx-state-hover.dx-state-focused {
    background-color: transparent;
}

.dx-scheduler-all-day-table-cell.dx-state-hover, .dx-scheduler-date-table-cell.dx-state-hover {
    background-color: transparent;
}

.dx-scheduler-appointment {
    color: white !important;
}

.dx-scheduler-appointment-tooltip-wrapper .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-hover,
.dx-scheduler-appointment-tooltip-wrapper .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-active {
    background-color: transparent !important;
}

.leopard-timeline-resourceview-text {
    color: #333;
}

.leopard-leftmenu-devextreme-sortablecontainer .leopard-leftmenu-treeview-directory {
    color: #333;
}

.leopard-leftmenu-devextreme-sortablecontainer .leopard-leftmenu-treeview-item {
    color: var(--mainColor);
}

.leopard-leftmenu-devextreme-sortablecontainer .dx-treeview-node:not(.dx-treeview-node-is-leaf).dx-state-focused .dx-item.dx-treeview-item {
    background-color: transparent;
}

.leopard-leftmenu-devextreme-sortablecontainer .dx-treeview-node:not(.dx-treeview-node-is-leaf).dx-state-hover .dx-item.dx-treeview-item {
    background-color: transparent;
}

.leopard-leftmenu-devextreme-sortablecontainer .dx-treeview-node:not(.dx-treeview-node-is-leaf).dx-state-selected .dx-item.dx-treeview-item {
    background-color: transparent !important;
}

.leopard-leftmenu-devextreme-sortablecontainer .dx-treeview-node:not(.dx-treeview-node-is-leaf) .dx-treeview-item.dx-state-hover {
    background-color: transparent;
}

.leopard-leftmenu-devextreme-sortablecontainer .dx-treeview-node.dx-treeview-node-is-leaf .dx-treeview-item.dx-state-hover {
    background-color: var(--shadeColor);
}

.leopard-leftmenu-devextreme-sortablecontainer .dx-treeview-node.dx-treeview-node-is-leaf.dx-state-focused .dx-item.dx-treeview-item {
    background-color: var(--mainColor);
}

.leopard-leftmenu-devextreme-sortablecontainer .dx-treeview-node.dx-treeview-node-is-leaf.dx-state-focused .leopard-leftmenu-treeview-item {
    color: white;
}

.leopard-leftmenu-devextreme-sortablecontainer .dx-treeview-node.dx-treeview-node-is-leaf.dx-state-focused .dx-item.dx-treeview-item .leopard-leftmenu-item-text {
    color: white;
}

.leopard-leftmenu-devextreme-sortablecontainer .leopard-leftmenu-category-text {
    color: #333 !important;
}

.leopard-leftmenu-devextreme-sortablecontainer .dx-treeview-node.dx-treeview-item-without-checkbox.dx-treeview-node-is-leaf.dx-state-selected .dx-treeview-item {
    background-color: var(--mainColor) !important;
}

.leopard-leftmenu-devextreme-sortablecontainer .dx-treeview-node.dx-treeview-item-without-checkbox.dx-treeview-node-is-leaf.dx-state-selected .dx-treeview-item .leopard-leftmenu-treeview-item {
    color: white !important;
}

.leopard-generic-hyperlink {
    color: var(--mainColor) !important;
}

.leopard-readonly-redtext input.dx-texteditor-input {
    color: red;
}

.leopard-photo-dragdrop-zone {
    border: 3px solid lightgrey;
}

.leopard-photo-dragdrop-zone.selected {
    border: 3px solid var(--mainColor);
}

.leopard-photo-dragdrop-zone.active {
    background-color: var(--shadeColor);
}

.leopard-topmenu-indicator-number-container {
    background-color: var(--mainColor);
}

.leopard-topmenu-indicator-number-text {
    color: white;
}

.dataimportpopup-lockscreen-cover {
    background-color: white;
}

.leopard-tabbedview-tabpanel-container.disabled .dx-tabpanel-tabs .dx-item-content.dx-tab-content {
    color: lightgray !important;
}

.dx-tabpanel > .dx-tabpanel-tabs .dx-tab.dx-tab-active .dx-tab-content,
.dx-tabpanel > .dx-tabpanel-tabs .dx-tab.dx-tab-selected .dx-tab-content {
    color: var(--mainColor) !important;
}

.leopard-text-highlighter {
    background-color: yellow !important;
    color: black !important;
}

.leopard-document-editor-loading-progress {
    background-color: white;
}

.document-upload-popup-panel-container {
    background-color: white;
}

.leopard_tabbedview_viewer_container_disabled_cover {
    background-color: transparent;
}

.fas.fa-spinner.fa-pulse {
    color: var(--mainColor) !important;
}

.leopard-notification-panel-container {
    border-left: 5px solid var(--mainColor);
}

.leopard-notification-item-icon {
    color: var(--mainColor);
}

.leopard-notification-item-icon.clicked {
    color: #86BC26 !important;
}

.leopard-notification-panel-container .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-focused,
.leopard-notification-panel-container .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-hover,
.leopard-notification-panel-container .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-active {
    background-color: var(--shadeColor) !important;
}

.leopard-topmenu-bar-notification-wrapper {
    background-color: var(--mainColor);
    color: white;
}

.leopard-topmenu-bar-notification-indicator {
    color: #C61938;
}

.leopard-formitem-empty-label .dx-field-item-label-text {
    color: transparent !important;
}

.leopard-border-left-stripe {
    border-left: 3px solid var(--mainColor);
}

.fas.fa-spinner {
    color: var(--mainColor);
}

.dx-button-mode-outlined {
    border: 1px solid rgba(0, 0, 0, .24) !important;
    background-color: transparent;
    color: rgba(0, 0, 0, .87);
}

.dx-button-mode-outlined.dx-state-selected {
    background-color: var(--mainColor);
    color: white;
}

.dx-button-mode-outlined.dx-state-hover {
    background-color: var(--shadeColor);
    color: rgba(0, 0, 0, .87);
}

.dx-calendar-caption-button.dx-state-hover .dx-button-content {
    color: rgba(0, 0, 0, .87) !important;
}

.dx-button-mode-outlined.dx-state-selected {
    background-color: var(--mainColor) !important;
    color: white !important;
}

.btn.btn-primary.active, .btn.btn-primary.focus, .btn.btn-primary:focus, .btn.btn-primary:hover:not(:disabled) {
    color: black !important;
    font-weight: bold !important;
}

.formField_container.withborder {
    border: 1px solid #DEE1E3;
}

.leopard-sidebar-stripe-content {
    color: white;
    background-color: var(--mainColor);
}

.leopard-notification-panel-container {
    background-color: white;
}

.leopard-notification-item-time {
    color: grey;
}

.dx-overlay-wrapper:not(.dx-overlay-modal) .dx-overlay-content.dx-menu-base .dx-submenu .dx-menu-item-wrapper .dx-item-content.dx-menu-item-content .dx-menu-item-text {
    color: black;
}

.dx-overlay-wrapper:not(.dx-overlay-modal) .dx-overlay-content.dx-menu-base .dx-submenu .dx-menu-item-wrapper .dx-menu-item-selected .dx-item-content.dx-menu-item-content .dx-menu-item-text {
    color: white;
}